import './style/App.css';
import { IconButton } from '@mui/material';

function App() {
  return (
      <header className="App-header">
        <img id="logo-img" src="/img/liga-logo.webp" alt="Logo liga halo br" draggable="false" />
        <h2>BEM-VINDO!</h2>
        <p id="texto-p">Ficamos felizes em anunciar a Liga Brasileira de Halo, a mais nova liga de Halo Competitivo do Brasil! A LBH nasce com o propósito de unificar a nossa comunidade nos esforços de promovê-la a um patamar INTERNACIONAL!</p>
        <span id="icons-wrapper">
          <a href="https://twitter.com/ligahalobr" target="blank"><IconButton variatn="outlined" color="primary" draggable="false">
            <img src="/img/twitter-icon.svg" alt="twitter redirect button" />
          </IconButton></a>
          <a href="https://www.twitch.tv/ligahalobr" target="blank">
          <IconButton color="primary">
            <img src="/img/twitch-icon.svg" alt="twitch redirect button" draggable="false"/>
          </IconButton></a>
          <a href="https://www.facebook.com/ligahalobr" target="blank">
          <IconButton color="primary">
            <img src="/img/facebook-icon.svg" alt="facebook redirect button" draggable="false"/>
          </IconButton></a>
          <a href="https://www.instagram.com/ligahalobr/" target="blank">
          <IconButton color="primary">
            <img src="/img/instagram-icon.svg" alt="facebook redirect button" draggable="false"/>
          </IconButton></a>
        </span>
      </header>
  );
}

export default App;
